:root {
  --primary: #95c6b1;
  --secondary: #3a405a;
  --gray: #f2f2f2;
  --highlight: #fff;
}

.body {
  margin: 0;
}

.bg_highlight {
  background-color: var(--primary);
  color: var(--secondary);
  padding: 0.1em;
}

/* Fonts */

h1 {
  font-size: 1em;
}

/* h2 {
  font-size: 0.8em;
} */

p {
  font-size: 0.8em;
}

@media (min-width: 31.25em) {
  h1 {
    font-size: 2em;
  }
  
  h2 {
    font-size: 1.6em;
  }
  
  p {
    font-size: 1em;
  }
}

.header {
  background-color: var(--primary);
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 20em;
  padding: 2em 1em 1em 1em;
}

.navigation .home a {
  text-decoration: none;
  color: var(--secondary);
  font-weight: 700;
}

.navigation .links {
  flex-direction: column;
}

.navigation .links a {
  float: left;
  text-decoration: none;
  color: var(--secondary);
  margin: 0 1em 0 1em;
  font-weight: 400;
}

.navigation .links a:not(.icon) {
  display: none;
}

.navigation .links.responsive a:not(.icon) {
  display: inline;
}

@media (min-width: 48em) {
  .navigation .links {
    justify-content: flex-end;
  }

  .navigation .links a:not(.icon) {
    display: inline-block;
    animation: none;
  }
  .navigation .links .icon {
    display: none;
  }
}

/* Hero */

.hero {
  margin: 5em auto;
  max-width: 20em;
  padding: 1em 2em 10em 2em;
}

.hero.small {
  margin: 2em auto;
  padding: 1em 2em 1em 2em;
}

.hero h1 {
  font-weight: 700;
}



/* Content */

.content {
  margin: 0 auto;
  max-width: 20em;  
  padding: 1em 2em 1em 2em;
  color: var(--secondary);
}


@keyframes revealNavResponsive {
  from {
    opacity: 0;
    transform: translate(0.2em,0);
  }
  to {
    opacity: 1;
    transform: translate(0,0);
  }
}
@keyframes hideNavResponsive {
  from {
    opacity: 1;
    transform: translate(0em,0);
  }
  to {
    opacity: 0;
    transform: translate(0.2em,0);
  }
}

@media (min-width: 31.25em) {
  .navigation, .hero, .content {
    max-width: 50em;
  }
}

@media (min-width: 50em) {
  .hero, .content {
    max-width: 50em;
  }
  .navigation {
    max-width: 65em;
  }
}

@media (min-width: 100em) {
  .hero, .content {
    max-width: 65em;
  }
  .navigation {
    max-width: 80em;
  }
}

@media (min-width: 120em) {
  .hero, .content {
    max-width: 80em;
  }
  .navigation {
    max-width: 100em;
  }
}

/* Submit form */

.search {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.search .submit_form {
  width: 100%;
}

.search .submit_form input {
  height: 4em;
  width: 60%;
  padding: 0 1em 0 1em;
}

.search .submit_form button {
  padding: 1.5em 1em 1.5em 1em;
  background-color: var(--secondary);
  color: var(--highlight);
  font-weight: 700;
}

@media (min-width: 31.25em) {
  .search .submit_form input {
    width: 80%;
  }
  .search .submit_form button {
    padding: 1.5em 2em 1.5em 2em;
  }
}

@media (min-width: 50em) {
  .search .submit_form input {
    width: 80%;
  }
  .search .submit_form button {
    padding: 1.5em 3em 1.5em 3em;
  }
}


/* Results */

.results_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 55em) {
.results_header {
    flex-direction: row;
  }
}

.bmc-button {
  margin-top: 1em;
}

.results {
  margin-bottom: 5em;
}

.result .list {
  background-color: var(--gray);
  display: flex;
  flex-wrap: wrap;
  padding: 1.6em;
}

.result .list .listitem {
  margin: 1em;
}

.result .list .listitem a {
  text-decoration: none;
  color: var(--secondary);
  font-size: 1.2em;
  font-weight: 500;
}

/*Description*/

.description p {
  font-size: 1.2em;
}